<template>
  <div>
    <div class="hero-section text-center">
      <div class="container">
        <router-link to="/signup" class="btn btn-primary">Join Our Beta</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderAndHero'
}
</script>

<style scoped>
header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10; /* Ensure the header is above the hero section */
}

.navbar {
  padding: 1rem 2rem;
  background: transparent; /* Remove background */
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
  color: white; /* Ensure text is visible on dark background */
}

.navbar-logo {
  height: 80px; /* Adjust the height to make the logo bigger */
}

.nav-link {
  margin-left: 1rem;
  font-size: 1rem;
  color: white; /* Ensure text is visible on dark background */
}

@media (max-width: 768px) {
  .navbar-nav {
    text-align: center;
  }
  .nav-link {
    margin-left: 0;
    margin-bottom: 1rem;
  }
}

.hero-section {
  background: url("https://i.imgur.com/zEFv8iR.png") no-repeat center center;
  background-size: cover;
  color: white;
  height: 85vh; /* 85% of the viewport height */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  animation: fadeIn 2s ease-in-out;
  position: relative;
  overflow: hidden; 
}


@media (max-width: 1200px) {
  .hero-section {
    background-image: url("https://i.imgur.com/0C8tSzZ.png");
  }
}
@media (max-width: 768px) {
  .hero-section {
    background-image: url("https://i.imgur.com/0C8tSzZ.png");
  }
}
.hero-section::before {
  content: '';
  position: absolute;
  top: 50%; /* Move clouds down */
  left: 0;
  width: 200%;
  height: 100%;
  background: url("https://gallery.yopriceville.com/var/resizes/Free-Clipart-Pictures/Cloud-PNG/Realistic_Cloud_PNG_Transparent_Clip_Art_Image.png?m=1629830700") repeat-x; /* A cloud image */
  opacity: 0.5; /* Adjust the opacity for a subtle effect */
  animation: moveFog 60s linear infinite; /* Animate the fog */
  z-index: 1;
}

.hero-section .container {
  position: relative;
  z-index: 2;
}

.hero-section .btn {
  margin-top: 330px; /* Move button down */
  padding: 0.75rem 1.5rem;
  font-size: 1.5rem; /* Increased size */
  animation: bounceIn 1.5s;
  background: rgba(0, 0, 0, 0.5); /* Low opacity black background */
  border: 2px solid white; /* Border to make it visible */
  color: white; /* Ensure text is visible */
  transition: background 0.3s, color 0.3s; /* Smooth transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow behind the button */
}

.hero-section .btn:hover {
  background: white; /* Change background on hover */
  color: black; /* Change text color on hover */
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(-5px);
  }
}

@keyframes moveFog {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
</style>
