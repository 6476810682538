<template>
  <header>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
          <img src="https://i.imgur.com/TYpeMnM.png" alt="PeakMind Logo" class="navbar-logo">
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/features">Features</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about">PeakMind</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/signup">Join Our Beta</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                More
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link class="dropdown-item" to="/faq">FAQ</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/resources">Resources</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.navbar {
  padding: 1rem 2rem;
  background: transparent; /* No background for the navbar */
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
}

.navbar-logo {
  height: 80px; /* Slightly larger logo */
}

.nav-link {
  margin-left: 1rem;
  font-size: 1rem;
  color: white;
}

.navbar-toggler {
  border: none;
  color: white;
}

.navbar-toggler-icon {
  color: white;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
  }
  
  .navbar-logo {
    height: 60px; /* Adjust logo size for smaller screens */
  }

  .navbar-nav {
    text-align: center;
  }

  .nav-link {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  
  .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.7); /* Background color for the collapsed menu */
    padding: 1rem; /* Padding for the collapsed menu */
  }
}
</style>
