<template>
  <footer class="footer bg-light text-center text-lg-start">
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
          <h5 class="text-uppercase">PeakMind</h5>
          <p>Our mission is to provide accessible, effective mental health support for Gen Z, bridging the gap between no care and entry-level mental health care in an engaging way.</p>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Links</h5>
          <ul class="list-unstyled mb-0">
            <li>
              <router-link class="text-dark" to="/">Home</router-link>
            </li>
            <li>
              <router-link class="text-dark" to="/about">About</router-link>
            </li>
            <li>
              <router-link class="text-dark" to="/features">Features</router-link>
            </li>
            <li>
              <router-link class="text-dark" to="/signup">Join Our Beta</router-link>
            </li>
            <li>
              <router-link class="text-dark" to="/faq">FAQ</router-link>
            </li>
            <li>
              <router-link class="text-dark" to="/resources">Resources</router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase mb-0">Contact</h5>
          <ul class="list-unstyled">
            <li>
              <a href="mailto:info@peakmind.com" class="text-dark">Email: info@peakmind.com</a>
            </li>
            <li>
              <a href="https://www.instagram.com/peakmind__/" target="_blank" class="text-dark">Instagram: @peakmind__</a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@peakmind__" target="_blank" class="text-dark">TikTok: peakmind__</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center p-3 bg-dark-bar text-white">
      &copy; 2024 PeakMind LLC.
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  background-color: #f8f9fa;
  padding: 1rem 0; 
  animation: fadeInUp 1s ease-in-out;
}

.footer .container {
  max-width: 1000px;
  animation: fadeIn 1.5s ease-in-out;
}

.footer h5 {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.footer p {
  color: #777; /* More subtle color */
  font-size: 1rem;
  line-height: 1.5;
}

.footer a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer a:hover {
  color: #0056b3;
}

.footer .bg-dark-bar {
  background-color: #08174A !important;
  padding: 1rem 0;
}

.footer .text-center {
  margin-top: 0; 
  animation: fadeIn 2s ease-in-out;
  font-size: 0.875rem; /* Smaller font size for more lowkey text */
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
