<template>
    <div>
      <Header />
      <div class="resources-header-section">
        <div class="container mt-5 text-center">
          <h1>Resources</h1>
          <p>Helpful Links for Mental Health Support</p>
        </div>
      </div>
      <div class="container resources-container">
        <ResourcesLink 
          v-for="(resource, index) in resources" 
          :key="index" 
          :title="resource.title" 
          :url="resource.url" 
          :description="resource.description"
        />
      </div>
      <CallToAction />
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from '../components/Header.vue'
  import Footer from '../components/Footer.vue'
  import ResourcesLink from '../components/ResourcesLink.vue'
  import CallToAction from '../components/CallToAction.vue'

  
  export default {
    name: 'Resources',
    components: {
      Header,
      ResourcesLink,
      CallToAction,
      Footer
    },
    data() {
      return {
        resources: [
          {
            title: 'Substance Abuse and Mental Health Services Administration',
            url: 'https://www.samhsa.gov',
            description: 'Provides resources and information for individuals struggling with substance abuse and mental health issues.'
          },
          {
            title: 'Suicide and Crisis Hotline',
            url: 'https://www.988lifeline.org',
            description: 'A 24/7 hotline providing free and confidential support for people in distress, prevention and crisis resources.'
          },
          {
            title: 'Crisis Text Line',
            url: 'https://www.crisistextline.org',
            description: 'Offers free, 24/7 support for those in crisis, connecting them with a trained crisis counselor via text.'
          },
          {
            title: 'Veteran Crisis Hotline',
            url: 'https://www.veterancrisisline.net',
            description: 'Provides confidential help for veterans and their families, available 24/7, and connects them with resources.'
          },
          {
            title: 'National Domestic Violence Hotline',
            url: 'https://www.thehotline.org',
            description: 'Offers essential tools and support to help survivors of domestic violence so they can live their lives free of abuse.'
          },
          {
            title: 'National Eating Disorders Association',
            url: 'https://www.nationaleatingdisorders.org',
            description: 'Supports individuals and families affected by eating disorders, and serves as a catalyst for prevention, cures, and access to quality care.'
          },
          {
            title: 'Mental Health America Helpline',
            url: 'https://www.mhanational.org',
            description: 'Provides support and resources for those dealing with mental health issues, and advocates for mental health as a critical part of overall wellness.'
          }
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .resources-header-section {
    background: url("https://i.imgur.com/HF56KSh.png") no-repeat center center;
    background-size: cover;
    padding: 100px 0; 
    position: relative;
    height: 40vh;
  }
  
  .resources-header-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.0); 
    z-index: 1;
  }
  
  .resources-header-section .container {
    position: relative;
    z-index: 2;
    color: white;
  }
  
  .container {
    max-width: 800px;
    margin: auto;
    padding: 0 1rem;
  }
  
  h1 {
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 22px;
    line-height: 1.6;
  }
  
  .resources-container {
    padding: 50px 0; /* Padding for the links container */
  }
  </style>
  