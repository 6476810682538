<template>
    <div class="who-we-are-section">
      <div class="container">
        <h2>Who We Are</h2>
        <p>
            PeakMind is a gamified mental health platform with a personalized therapeutic AI companion. We make mental health care engaging, interactive, and customized just for you. With PeakMind, you have a guided path to overcome all mental health challenges. Our platform combines an engaging game with evidence-based strategies to empower users in achieving their mental health goals within a fun and supportive environment.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WhoWeAre'
  }
  </script>
  
  <style scoped>
  .who-we-are-section {
    background-color: white;
    padding: 6rem 2rem 0 2rem; 
    text-align: center;
    margin-top: -50px; /* Adjust this value as needed to remove the white space */
  }
  
  .who-we-are-section .container {
    max-width: 800px;
    margin: auto;
  }
  
  .who-we-are-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: #08174A; 
  }
  
  .who-we-are-section p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
  }
  </style>
  