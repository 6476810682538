<template>
  <div class="container mt-5">
    <div class="row mt-4">
      <div
        class="col-12 col-md-6 col-lg-4 service-card"
        v-for="(service, index) in services"
        :key="index"
        :class="{ 'middle-card': isMiddleCard(index) }"
      >
        <div class="card">
          <div class="card-body">
            <img :src="service.image" alt="Service Image" class="card-img-top">
            <h3>{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesOverview',
  data() {
    return {
      services: [
        { title: "Engaging", description: "PeakMind transforms your mental health journey into an interactive and immersive experience, making it both educational and engaging.", image: "https://i.imgur.com/FaqmTJR.png" },
        { title: "Personalized", description: "PeakMind tailors its solutions to address your unique mental health conditions, empowering you to tackle the specific issues you face.", image: "https://i.imgur.com/e9qnmIJ.png" },
        { title: "Accessible", description: "PeakMind ensures that everyone, regardless of background, financial status, or access to formal mental health services, has the opportunity to enhance their well-being.", image: "https://i.imgur.com/yqLQ9Cy.png" }
      ]
    }
  },
  methods: {
    isMiddleCard(index) {
      // Assuming you have exactly 3 cards
      return index === 1;
    }
  }
}
</script>

<style scoped>
.container {
  padding: 0 1rem;
}

h2 {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 2rem;
  animation: fadeInDown 1s ease-in-out;
}

.service-card {
  margin-bottom: 20px;
  padding: 0 20px; 
  animation: fadeInUp 1s ease-in-out;
  margin-top: -80px; 
}

.middle-card .card {
  transform: scale(1.13); 
}

.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-10px);
}

.card-body {
  padding: 20px;
  text-align: center;
}

.card-img-top {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}

h3 {
  font-weight: 600;
  margin-top: 20px;
}

p {
  color: #555;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  .service-card {
    margin-top: 20px;
  }
  .middle-card .card {
    transform: none;
  }
}
</style>
