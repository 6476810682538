<template>
    <div class="statistics-section">
      <img src="https://i.imgur.com/Bqu88mY.png" alt="Mental Health Statistics" class="statistics-image">
    </div>
  </template>
  
  <script>
  export default {
    name: 'MentalHealthStatistics'
  }
  </script>
  
  <style scoped>
  .statistics-section {
    margin: 0;
    text-align: center;
  }
  
  .statistics-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Media Queries for Responsive Images */
  @media (max-width: 1200px) {
    .statistics-image {
      content: url("https://i.imgur.com/YCn81TD.png");
    }
  }
  
  @media (max-width: 768px) {
    .statistics-image {
      content: url("https://i.imgur.com/YCn81TD.png");
    }
  }
  </style>
  