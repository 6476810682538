<template>
  <div>
    <Header />
    <div class="faq-header-section">
      <div class="container mt-5 text-center">
        <h1>FAQ</h1>
        <p>Frequently Asked Questions</p>
      </div>
    </div>
    <div class="container faq-items-container">
      <FAQItem 
        v-for="(faq, index) in faqs" 
        :key="index" 
        :question="faq.question" 
        :answer="faq.answer" 
      />
    </div>
    <CallToAction />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import FAQItem from '../components/FAQItem.vue'
import CallToAction from '../components/CallToAction.vue'


export default {
  name: 'FAQ',
  components: {
    Header,
    FAQItem,
    CallToAction,
    Footer
  },
  data() {
    return {
      faqs: [
        {
          question: 'What is PeakMind?',
          answer: 'PeakMind is a wellness platform designed to prevent simpler mental health conditions, such as anxiety, from escalating into more severe conditions like depression.'
        },
        {
          question: 'When will PeakMind be available?',
          answer: 'PeakMind will be available on the app store in September 2024.'
        },
        {
          question: 'Who is PeakMind made for?',
          answer: 'PeakMind is designed for 16-28-year-olds who are struggling mentally, experiencing difficulty accessing formal care, and seeking self-improvement.'
        },
        {
          question: 'How much does PeakMind cost?',
          answer: 'PeakMind is free, with two paid tiers for unlimited access to premium features.'
        },
        {
          question: 'How is PeakMind different from other apps?',
          answer: 'PeakMind engages users through gamification while personalizing the experience to each individual, unlike any other self-care apps.'
        },
        {
          question: 'Does PeakMind provide therapy?',
          answer: 'PeakMind is NOT a therapy app. It is intended to supplement formal therapy practices and is not a substitute for therapy.'
        },
        {
          question: 'How does PeakMind ensure your data is safe and secure?',
          answer: 'At PeakMind, we prioritize your privacy and data security. Our platform employs advanced encryption methods and complies with stringent data protection regulations to ensure your information remains confidential and secure.'
        }
      ]
    }
  }
}
</script>

<style scoped>
.faq-header-section {
  background: url("https://i.imgur.com/HF56KSh.png") no-repeat center center;
  background-size: cover;
  padding: 100px 0; 
  position: relative;
  height: 40vh;
}

.faq-header-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.0); 
  z-index: 1;
}

.faq-header-section .container {
  position: relative;
  z-index: 2;
  color: white;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
}

h1 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 20px;
}

p {
  font-size: 22px;
  line-height: 1.6;
}

.faq-items-container {
  padding: 50px 0; /* Add padding above and below the FAQ items */
}
</style>
