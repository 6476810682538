<template>
  <div class="cta-section text-center">
    <div class="container">
      <h2>Ready to Improve Your Mental Health?</h2>
      <router-link to="/signup" class="btn btn-primary">Sign Up Now</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallToAction'
}
</script>

<style scoped>
.cta-section {
  background: #1E416C;
  color: white;
  margin-top: 50px;
  padding: 4rem 0;
  animation: fadeIn 1.5s ease-in-out;
}

.cta-section .container {
  max-width: 800px;
  margin: auto;
  animation: fadeInUp 1.5s ease-in-out;
}

.cta-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
}

.cta-section .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  background-color: #f0f0f0;
  color: #3c59a5;
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cta-section .btn:hover {
  background-color: #A5D0E3;
  color: #fff;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
