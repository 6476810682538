<template>
    <div class="features-section py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 mb-4" v-for="(feature, index) in features" :key="index">
            <div class="card feature-card h-100 text-center">
              <div class="card-body">
                <img :src="feature.image" class="card-img-top mb-3" alt="Feature Image">
                <h5 class="card-title">{{ feature.title }}</h5>
                <p class="card-text">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FeaturesSection',
    data() {
      return {
        features: [
          {
            title: 'Game',
            description: 'Dive into the PeakMind game, featuring various tracks or mountains, each designed for different mental health conditions. Learn coping mechanisms, complete interactive activities, and empower yourself to handle your specific struggles. Select the mountain tailored to your needs and access educational modules, mini-games, coping strategies, trigger mapping, wellness questions, and more!',
            image: 'https://i.imgur.com/IF7I48Y.png'
          },
          {
            title: 'Self Care Suite',
            description: 'Begin tracking your self-care tasks with the Self Care Suite. Monitor progress by tracking activities such as sleep hours or daily water intake. Complete daily check-ins to discover positive patterns in your life. View your progress in a centralized analytics page.',
            image: 'https://i.imgur.com/ch4HDBK.png'
          },
          {
            title: 'Communities',
            description: 'Connect with others who face similar challenges. Our communities provide a centralized forum for different mental health conditions/interest topics, allowing you to chat, post, and connect with like-minded individuals for support and encouragement.',
            image: 'https://i.imgur.com/5lhirMY.png'
          },
          {
            title: 'Therapeutic AI Coach',
            description: 'Receive immediate, 24/7 support from your “Sherpa” AI coach. Sherpa offers science-based support, prompting you with questions related to your struggles and providing helpful advice and coping mechanisms for life\'s challenges.',
            image: 'https://i.imgur.com/fQNqINP.png'
          },
          {
            title: 'Personalized Plan',
            description: 'Get a personalized wellness plan outlining coping mechanisms and activities for self-improvement. This plan is specifically tailored to your conditions, helping you move forward one step at a time.',
            image: 'https://i.imgur.com/cjF5YWL.png'
          },
          {
            title: 'Journal',
            description: 'Our interactive journal entices users with interactive journal prompts, providing all users with the guidance they need to regularly journal and write down their thoughts.',
            image: 'https://i.imgur.com/cjF5YWL.png'
          }
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .features-section {
    background-color: #fff; /* Set background to white */
    padding: 5rem 0;
  }
  
  .feature-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .feature-card .card-body {
    padding: 2rem;
  }
  
  .feature-card .card-img-top {
    max-width: 450px;
    margin: auto;
  }
  
  .feature-card .card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .feature-card .card-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .features-section {
    animation: fadeIn 2s ease-in-out;
  }
  
  .feature-card {
    animation: fadeInUp 1s ease-in-out;
  }
  </style>
  